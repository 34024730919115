// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-marketing-services-content-marketing-index-js": () => import("./../../../src/pages/marketing-services/content-marketing/index.js" /* webpackChunkName: "component---src-pages-marketing-services-content-marketing-index-js" */),
  "component---src-pages-marketing-services-index-js": () => import("./../../../src/pages/marketing-services/index.js" /* webpackChunkName: "component---src-pages-marketing-services-index-js" */),
  "component---src-pages-marketing-services-pay-per-click-index-js": () => import("./../../../src/pages/marketing-services/pay-per-click/index.js" /* webpackChunkName: "component---src-pages-marketing-services-pay-per-click-index-js" */),
  "component---src-pages-marketing-services-seo-index-js": () => import("./../../../src/pages/marketing-services/seo/index.js" /* webpackChunkName: "component---src-pages-marketing-services-seo-index-js" */),
  "component---src-pages-marketing-services-social-media-marketing-index-js": () => import("./../../../src/pages/marketing-services/social-media-marketing/index.js" /* webpackChunkName: "component---src-pages-marketing-services-social-media-marketing-index-js" */),
  "component---src-pages-marketing-services-web-design-index-js": () => import("./../../../src/pages/marketing-services/web-design/index.js" /* webpackChunkName: "component---src-pages-marketing-services-web-design-index-js" */)
}

